<template>
    <div class="note-single mb-2">
        <div class="note-card note-social">
            <div class="card border-0">
                <label :for="answer.alid" @click="selectAsAnswer(answer.alid)" :id="answer.alid" class="link">
                <div class="card-body" :class="{'correctAnswer' : correctAnswer}">
                    <span class="atbd-tag tag-primary tag-transparented" style="position: absolute;right: 15px;" v-show="correctAnswer && type == 8">{{answer.rank}}</span>
                    <h4 class="note-title">
                        <div class="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                            
                            <span class="badge badge-primary ml-0 mr-2" v-if="meta.label != 'none'">{{labels[meta.label][meta.index]}}</span>
                            <!-- <input class="checkbox" type="checkbox" :id="answer.alid"> -->
                            <label :for="answer.alid"> {{correctAnswer ? 'correct' : 'incorrect'}}</label>
                        </div>  
                    </h4>
                    <!-- <h4 class="note-title" v-else-if="[1,2].indexOf(type) >= 0">
                        <div class="radio-theme-default custom-radio ">
                             <span class="badge badge-primary ml-0 mr-2">{{labels[meta.label][meta.index]}}</span>
                            <input class="radio" type="radio" name="radio-optional" value="0" :id="answer.alid">
                            
                            <label :for="answer.alid">
                            <div class="fa fa-check-circle"></div>
                                <span class="radio-text">incorrect</span>
                            </label>
                        </div>
                        
                    </h4> -->
                    <!-- <h4 class="note-title" v-else-if="type == 16">
                        <div class="radio-theme-default custom-radio ">
                              <span class="badge badge-primary ml-0 mr-2">A</span>
                            <input class="radio" type="radio" name="radio-optional" value="0" :id="answer.alid">
                            <label for="radio-hl8">
                                <span class="radio-text">incorrect</span>
                            </label>
                        </div>
                    </h4> -->
                    <!-- <h4 class="note-title">
                        <span class="radio-text">incorrect</span>
                            <span class="flex1"></span>
                            <span class="badge badge-primary">4</span>
                    </h4> -->

                    <p class="note-excerpt">
                        <span v-if="answer.engine == 'text'"> <VueMarkdown :source="answer.data" /></span>
                        <span v-else-if="answer.engine == 'latex'" v-katex="{ expression: ' ' + answer.data, options: { throwOnError: false }}"></span>
                        <span v-else>{{answer.data}}</span>
                    </p>
                    <div class="note-action" v-if="!meta.preview">
                        <div class="note-action__left">
                            <span @click.stop="$emit('answerOperations', {action:'edit', answer})" v-if="type == 16 || answer.engine == 'media'"><i class="las la-pen mr-2 ml-0"></i></span>
                            <span @click.stop="removeAnswer(answer, correctAnswer)" :class="{'el-fade disabled': correctAnswer}" v-if="type != 16"><i class="las la-trash" :class="{'text-primary':!correctAnswer}"></i></span>
                            <!-- <span @click="$emit('trashContent', {from:'answer', data: answer})"  class="ml-2"><i class="las la-trash"></i></span> -->
                        </div>
                    </div>
                </div>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown-render';
import { apiClient } from '../../plugins/restclient';
import EventBus from '@/plugins/eventbus';

export default {
    props:['answer', 'type', 'meta'],
    components:{
        VueMarkdown
    },
    data(){
        return{
            labels:{
                roman:['i', 'ii', 'iii', 'iv', 'v'],
                alpha:['a', 'b', 'c', 'd', 'e'],
                number:['1', '2', '3', '4', '5'],
                bullet: '*'
            },
            correctAnswer: false,
            rank:''
        }
    },
    created(){
        this.correctAnswer = this.meta.correct
        EventBus.$on('answerSelect', this.cardSelection)
        EventBus.$on('deselectAnswer', this.deselectAnswer)
        EventBus.$on('answerRank', this.answerRank)
    },
    methods:{
        removeAnswer(answer, correct){
            if(correct){
                return
            }
            this.$store.commit('loader', true)
            apiClient.patch('v1/office/assessment/answer/remove',{
                alid: answer.alid
            })
            .then(()=>{
                this.$store.commit('loader', false)
                this.$emit('answerOperations', {action: 'delete', answer})
            })
            .catch(err =>{
                this.$store.commit('loader', false)
                this.$store.commit('ctoast', {type: 'danger', message: err.response.data.message, timeout: 7000})
            })
            
        },
        selectAsAnswer(alid){
            if(this.meta.preview){
                return
            }
            EventBus.$emit('answerSelect', alid)

        },
        cardSelection(alid){

            if(this.type == 4 || this.type == 8){
                if(this.answer.alid == alid){
                    this.correctAnswer = !this.correctAnswer
                }
            }else if(this.type == 1 || this.type == 2){
                this.correctAnswer = false
                if(alid == this.answer.alid){
                    this.correctAnswer = true
                }
            }
        },
        deselectAnswer(answers){

            if(answers.indexOf(this.answer.alid) >= 0){
                this.correctAnswer = false
            }
        },
        answerRank(answers){
            this.answer.rank = answers.indexOf(this.answer.alid) + 1

        }
    }
}
</script>
<style scoped>
.correctAnswer{
    background: #b4ead6;
    border-radius: 5px;
}
.card label{
    margin-bottom: 0px !important;
}
.card-body{
    border-radius: 5px;
}
</style>